import {Form, Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import {
    composeValidators, isUrlConstraint,
    minValueConstraint,
    renderErrorBlock,
    requiredConstraint
} from "../../../functions/validator";
import {useEffect, useState} from "react";

export default function ShopForm({form, current}) {
    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
        if (current) {
            setInitialValues({
                note: current.note,
                records: current.records.map((rec) => {
                    return {
                        price: rec.price,
                        link: rec.link,
                        name: rec.name,
                        quantity: rec.quantity,
                        size: rec.size,
                        color: rec.color
                    }
                })
            });
        } else {
            setInitialValues({records: [undefined]})
        }
    }, [current?.id]);

    return (
        <div className="p-0 g-2 parcels-form">
            <div className="col">
                <h1 className="parcels-list-title">Товары для покупки</h1>
                <p className="parcels-list-text mt-3">
                    Пожалуйста дайте нам знать какие товары Вы хотите чтобы
                    мы купили. Обработка заказов происходит в течение 1-2 дней.
                </p>
            </div>
            <Form
                form={form}
                mutators={{...arrayMutators}}
                initialValues={initialValues}
                render={({handleSubmit, form: {mutators: {push}}}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FieldArray name="records">
                                {({fields}) => {
                                    return (
                                        <>
                                            {fields.map((fieldName, index) => (
                                                <div className="row" key={`records_${index}`}>
                                                    <Field
                                                        name={`${fieldName}.link`}
                                                        validate={composeValidators(requiredConstraint, isUrlConstraint)}
                                                    >
                                                        {({input, meta}) => (
                                                            <>
                                                                <div className="col-md-10">
                                                                    <label className="form-label">Ссылка товара</label>
                                                                    <input className="form-control" {...input}/>
                                                                    {renderErrorBlock(meta)}
                                                                </div>
                                                                {fields.length > 1 ? <div className="col-md-2">
                                                                    <br/>
                                                                    <button style={{marginTop: '10px'}}
                                                                            onClick={() => fields.remove(index)}
                                                                            className="btn modal-outline-btn">
                                                                        <i className="ri-delete-bin-2-line"></i>
                                                                    </button>
                                                                </div> : ''
                                                                }
                                                            </>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        name={`${fieldName}.name`}
                                                        validate={composeValidators(requiredConstraint)}
                                                    >
                                                        {({input, meta}) => (
                                                            <div className="col-md-12">
                                                                <label className="form-label">Название товара</label>
                                                                <input className="form-control" {...input}/>
                                                                {renderErrorBlock(meta)}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        name={`${fieldName}.price`}
                                                        type="number"
                                                        validate={composeValidators(requiredConstraint, minValueConstraint(1))}
                                                    >
                                                        {({input, meta}) => (
                                                            <div className="col-md-6">
                                                                <label className="form-label">Цена за ед</label>
                                                                <div className="input-group mb-3">
                                                                    <input type="text" className="form-control"
                                                                           name="track-price" {...input}/>
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                {renderErrorBlock(meta)}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        name={`${fieldName}.quantity`}
                                                        type="number"
                                                        validate={composeValidators(requiredConstraint, minValueConstraint(1))}
                                                    >
                                                        {({input, meta}) => (
                                                            <div className="col-md-6">
                                                                <label className="form-label">Количество</label>
                                                                <div className="input-group mb-3">
                                                                    <input type="number" className="form-control"
                                                                           name="track-count" min="1"
                                                                           placeholder="Количество" {...input}/>
                                                                    <span className="input-group-text">ШТ</span>
                                                                </div>
                                                                {renderErrorBlock(meta)}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${fieldName}.color`}>
                                                        {({input, meta}) => (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Цвет</label>
                                                                <input className="form-control" {...input}/>
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${fieldName}.size`}>
                                                        {({input, meta}) => (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Размер</label>
                                                                <input className="form-control" {...input}/>
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {(fields.length > 1 && fields.length - 1 !== index) ?
                                                        <div className="col">
                                                            <hr className="mt-5 mb-4"/>
                                                        </div> : ''
                                                    }
                                                </div>
                                            ))}
                                        </>
                                    )
                                }}
                            </FieldArray>
                            <div className="col-md-12 text-end my-3">
                                <button
                                    className="btn new-address-btn"
                                    onClick={() => push('records', null)}
                                >
                                    <i className="ri-add-line"></i> Добавить новый продукт
                                </button>
                            </div>
                            <Field name="note">
                                {({input}) => (
                                    <div className="col-md-12">
                                        <label>Комментарий для оператора</label>
                                        <textarea {...input} rows="3"></textarea>
                                    </div>
                                )}
                            </Field>
                        </form>
                    )
                }}
            />
        </div>
    )
}