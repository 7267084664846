import {Outlet} from 'react-router-dom'
import {useContext} from "react";
import Context from "../../functions/context";
import CreateModal from "./CreateModal";
import {TOGGLE_ADD_MODAL} from "../../functions/constants";
import AddressModal from "./components/AddressModal";
import ActiveLink from "../../components/ActiveLink";

export default function ParcelPage() {
    const {mainState: {visibleAddressModal}, mainDispatch} = useContext(Context)
    return (
        <div className="parcles-content">
            <div className="parcles-row  align-items-start flex-column flex-md-row ">
                <ul className="nav nav-pills mb-3 " role="tablist">
                    <li className="nav-item" role="presentation">
                        <ActiveLink className="nav-link" to='/cabinet/parcels/tracking'>
                            Посылки
                        </ActiveLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <ActiveLink
                            className="nav-link text-nowrap"
                            to="/cabinet/parcels/shop"
                        >
                            Помощь в покупке
                        </ActiveLink>
                    </li>
                </ul>
                <button className="new-parcels-button btn text-nowrap mb-3"
                        onClick={() => mainDispatch({type: TOGGLE_ADD_MODAL, payload: {visible: true}})}
                >
                    <i className="ri-add-line"></i> Новый заказ
                </button>
            </div>
            <div className="tab-content tab-inner">
                <div className="tab-pane fade show active">
                    <Outlet/>
                </div>
            </div>
            <CreateModal/>
            {visibleAddressModal ? <AddressModal country={2} secondary={true}/> : ''}
        </div>
    );
}