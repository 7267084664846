import {useNavigate} from "react-router-dom";
import IntroInfoModal from "../components/IntroInfoModal";
import {useState} from "react";

export default function Intro() {
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);

    return (
        <>
            <section id="intro">
                <div className="intro-row" onClick={() => {
                    setInfoModal(true)
                }}>
                    <div className="intro-item">
                        <h5>I am in</h5>
                        <h1>United States</h1>
                    </div>
                </div>
                <div className="intro-row" onClick={() => {
                    setInfoModal(true);
                }}>
                    <div className="intro-item">
                        <h5>I am in</h5>
                        <h1>Uzbekistan</h1>
                    </div>
                </div>
            </section>
            <IntroInfoModal
                visible={infoModal}
                onCancel={() => setInfoModal(false)}
            />
        </>
    )
}