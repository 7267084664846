import CustomModal from "../../components/CustomModal";
import {useContext, useEffect, useMemo} from "react";
import Context from "../../functions/context";
import {
    ADD_UPDATE_SHOP,
    ADD_UPDATE_TRACKING,
    SET_SECTION,
    TOGGLE_ADD_MODAL,
    UPDATE_STEP_MODAL
} from "../../functions/constants";
import {createForm} from "final-form";
import ShopForm from "./components/ShopForm";
import arrayMutators from "final-form-arrays";
import {normalizeErrorFields} from "../../functions/request-helper";
import AddressChooser from "./components/AddressChooser";
import ShopDetail from "./components/ShopDetail";
import TrackingForm from "./components/TrackingForm";
import TrackingDetail from "./components/TrackingDetail";
import {createShop, createTracking} from "../../functions/api";


const stepRender = (section, step, form) => {
    switch (step) {
        case 1:
            return section === 'tracking' ? <TrackingForm form={form}/> : <ShopForm form={form}/>;
        case 2:
            return <AddressChooser/>
        case 3:
            return section === 'tracking' ?
                <TrackingDetail values={form.getState().values}/> :
                <ShopDetail values={form.getState().values}/>
    }
}

export default function CreateModal() {
    const {mainState, mainDispatch} = useContext(Context);
    const {showAddModal, currentStep, section, currentAddress} = mainState;
    const onClose = () => {
        mainDispatch({type: TOGGLE_ADD_MODAL, payload: {visible: false}});
        mainDispatch({type: SET_SECTION, payload: undefined});
    }

    const onSubmit = async (values) => {
        console.log(values);
        try {
            if (section === 'tracking') {
                const items = await createTracking(values);
                for (const item of items) {
                    mainDispatch({type: ADD_UPDATE_TRACKING, payload: item});
                }
            } else {
                const item = await createShop(values);
                mainDispatch({type: ADD_UPDATE_SHOP, payload: item});
            }
            onClose();
        } catch (res) {
            if (res?.response?.status === 422) {
                const error = res.response.data;
                const fieldErrors = normalizeErrorFields(error);
                if (Object.values(fieldErrors).length) {
                    return fieldErrors;
                }
            }
        }
    }
    const form = useMemo(() => {
        return createForm({
            onSubmit: onSubmit,
            initialValues: {records: [undefined]},
            subscription: {values: true, valid: true},
            mutators: {...arrayMutators}
        });
    }, [section]);

    const handleNext = () => {
        switch (currentStep) {
            case 1:
                mainDispatch({
                    type: UPDATE_STEP_MODAL,
                    payload: 2
                });
                break;
            case 3:
                form.submit();
                break;
            case 2:
                if (currentAddress) {
                    mainDispatch({
                        type: UPDATE_STEP_MODAL,
                        payload: 3
                    })
                }
                break;
            default:
                mainDispatch({
                    type: UPDATE_STEP_MODAL,
                    payload: Math.min(4, currentStep + 1)
                })
        }
    }

    useEffect(() => {
        return () => {
            mainDispatch({type: SET_SECTION, payload: undefined})
        }
    }, []);

    return (
        <CustomModal
            visible={showAddModal}
            onCancel={onClose}
            cssClass="parcels-order"
        >
            <div className="modal-body">
                {section &&
                    <div className="parcels-steps-group">
                        <div className="parcels-modal-steps">
                            {[1, 2, 3, 4].map(st => (
                                <span key={st}
                                      className={`step ${st <= currentStep ? 'active' : ''} `}><span>{st}</span>
                                    </span>
                            ))}
                        </div>
                        <div className="parcles-modal-arrows">
                            <button className="btn-prev"><i className="ri-arrow-left-s-line"></i></button>
                            <button className="btn-next"><i className="ri-arrow-right-s-line"></i></button>
                        </div>
                    </div>
                }
                {!section ?
                    <div className="parcels-order d-flex flex-column">
                        <div className="col-12 mt-3">
                            <h1 className="parcels-list-title">Выберите тип заказа *</h1>
                        </div>
                        <a className="parcels-select mt-3"
                           onClick={() => mainDispatch({type: SET_SECTION, payload: 'tracking'})}>
                            <i className="ri-shopping-cart-2-line"></i>
                            Помощь в покупке
                        </a>
                        <a className="parcels-select mt-3"
                           onClick={() => mainDispatch({type: SET_SECTION, payload: 'shop'})}
                        >
                            <i className="ri-information-line"></i>
                            Интернет заказ
                        </a>
                    </div>
                    : <div className="parcels-form">
                        <div className="tab" style={{display: 'block'}}>
                            {stepRender(section, currentStep, form)}
                        </div>
                        <div className="col text-end mt-3 ">
                            <button type="button" className="btn prev-btn"
                                    style={{display: currentStep > 1 ? 'inline' : 'none'}}
                                    onClick={() => mainDispatch({
                                        type: UPDATE_STEP_MODAL,
                                        payload: Math.max(currentStep - 1, 1)
                                    })}
                            >
                                Предыдущий
                            </button>
                            <button type="button"
                                    className="btn next-btn"
                                    onClick={handleNext}
                            >
                                {currentStep !== 3 ? 'Продолжить' : 'Оформить'}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </CustomModal>
    );
}