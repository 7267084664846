export const ShopCategories = ['popular', 'marketplace', 'store', 'clothes', 'shoes', 'beauty', 'children', 'electronics', 'appliances', 'sport', 'health', 'other'];

export const LOAD_PROFILE = 'load_profile';
export const UNSET_PROFILE = 'unset_profile';
export const SHOWED_MODAL = 'SHOWED_MODAL';

export const LOAD_TRACKING = 'LOAD_TRACKING';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
export const ADD_UPDATE_TRACKING = 'ADD_UPDATE_TRACKING';
export const REMOVE_TRACKING = 'REMOVE_TRACKING';

export const LOAD_SHOP = 'LOAD_SHOP';
export const ADD_UPDATE_SHOP = 'ADD_UPDATE_SHOP';
export const REMOVE_SHOP = 'REMOVE_SHOP';

export const TOGGLE_ADD_MODAL = 'TOGGLE_ADD_MODAL';
export const TOGGLE_ADDRESS_MODAL = 'TOGGLE_ADDRESS_MODAL';
export const UPDATE_STEP_MODAL = 'UPDATE_STEP_MODAL';
export const SET_SECTION = 'SET_SECTION';
export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS';

export const SET_RESET_CODE = 'SET_RESET_CODE';