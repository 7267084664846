import React, {lazy, useEffect, useReducer, Suspense} from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Context from "./functions/context";
import {initialState, MainReducer} from "./reducer/main";
import {loadProfile} from "./functions/api";
import {LOAD_PROFILE} from "./functions/constants";
import Intro from "./pages/Intro";
import './App.css';
import ParcelPage from "./pages/cabinet/ParcelPage";
import {ConfirmContextProvider} from "./functions/confirm-context-provider";
import ConfirmDialog from "./components/ConfirmDialog";

const MainUzbek = lazy(() => import("./pages/uz/MainUzbek"));
const MainUsa = lazy(() => import("./pages/en/MainUsa"));

const ServicePage = lazy(() => import("./pages/ServicePage"));
const IndexPage = lazy(() => import("./pages/uz/IndexPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const FaqPage = lazy(() => import("./pages/FaqPage"));
const PlanPage = lazy(() => import("./pages/PlanPage"));
const HelpShopPage = lazy(() => import("./pages/HelpShopPage"));
const ShopPage = lazy(() => import("./pages/ShopPage"));
const RegistrationPage = lazy(() => import("./pages/cabinet/RegistrationPage"));
const LiveConcertPage = lazy(() => import("./pages/LiveConcertPage"));
const LoginPage = lazy(() => import("./pages/cabinet/LoginPage"));
const ForgotPasswordPage = lazy(() => import("./pages/cabinet/ForgotPasswordPage"));
const CheckForgotPasswordPage = lazy(() => import("./pages/cabinet/CheckForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./pages/cabinet/ResetPasswordPage"));
const CabinetMainPage = lazy(() => import("./pages/cabinet/CabinetMainPage"));
const ProfilePage = lazy(() => import("./pages/cabinet/ProfilePage"));
const ProfileEditMainPage = lazy(() => import("./pages/cabinet/ProfileEditMainPage"));
const ProfileEditPage = lazy(() => import("./pages/cabinet/ProfileEditPage"));
const ProfilePasswordPage = lazy(() => import("./pages/cabinet/ProfilePasswordPage"));
const LogoutPage = lazy(() => import("./pages/cabinet/LogoutPage"));
const AddressPage = lazy(() => import("./pages/cabinet/AddressPage"));
const TrackingPage = lazy(() => import("./pages/cabinet/TrackingPage"));
const CabinetShopPage = lazy(() => import("./pages/cabinet/ShopPage"));


function App() {
    const [mainState, mainDispatch] = useReducer(MainReducer, initialState);
    const {modalActive} = mainState;

    useEffect(() => {
        if (localStorage.getItem('cabinet_token')) {
            loadProfile(localStorage.getItem('cabinet_token'))
                .then(data => {
                    mainDispatch({type: LOAD_PROFILE, payload: data})
                })
        }
    }, []);
    return (
        <div className={`app ${modalActive ? 'modal-open' : ''}`}>
            <Context.Provider value={{mainState, mainDispatch}}>
                <ConfirmContextProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Intro/>}/>
                            <Route path="/live" element={<Suspense fallback={<>...</>}><LiveConcertPage/></Suspense>} />
                            <Route path="for-usa" element={<Suspense fallback={<>...</>}><MainUsa/></Suspense>}>
                            </Route>
                            <Route path="for-uzbek" element={<Suspense fallback={<>...</>}><MainUzbek/></Suspense>}>
                                <Route path="" element={<IndexPage/>}/>
                            </Route>
                            <Route path="plans" element={<Suspense fallback={<>...</>}><PlanPage/></Suspense>}/>
                            <Route path="shops" element={<Suspense fallback={<>...</>}><ShopPage/></Suspense>}/>
                            <Route path="services" element={<Suspense fallback={<>...</>}><ServicePage/></Suspense>}/>
                            <Route path="about" element={<Suspense fallback={<>...</>}><AboutUsPage/></Suspense>}/>
                            <Route path="contact" element={<Suspense fallback={<>...</>}><ContactPage/></Suspense>}/>
                            <Route path="help" element={<Suspense fallback={<>...</>}><FaqPage/></Suspense>}/>
                            <Route path="help-shop" element={<Suspense fallback={<>...</>}><HelpShopPage/></Suspense>}/>
                            <Route path="registration"
                                   element={<Suspense fallback={<>...</>}><RegistrationPage/></Suspense>}/>
                            <Route path="login" element={<Suspense fallback={<>...</>}><LoginPage/></Suspense>}/>
                            <Route path="forgot-password"
                                   element={<Suspense fallback={<>...</>}><ForgotPasswordPage/></Suspense>}/>
                            <Route path="check-code"
                                   element={<Suspense fallback={<>...</>}><CheckForgotPasswordPage/></Suspense>}/>
                            <Route path="reset-password"
                                   element={<Suspense fallback={<>...</>}><ResetPasswordPage/></Suspense>}/>
                            <Route path="cabinet" element={<Suspense fallback={<>...</>}><CabinetMainPage/></Suspense>}>
                                <Route path="profile" element={<ProfilePage/>}/>
                                <Route path="address" element={<AddressPage/>}/>
                                <Route path="parcels" element={<ParcelPage/>}>
                                    <Route path="tracking" element={<TrackingPage/>}/>
                                    <Route path="shop" element={<CabinetShopPage/>}/>
                                </Route>
                                <Route path="profile/edit" element={<ProfileEditMainPage/>}>
                                    <Route path="" element={<ProfileEditPage/>}/>
                                    <Route path="password" element={<ProfilePasswordPage/>}/>
                                </Route>
                                <Route path="logout" element={<LogoutPage/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                    <ConfirmDialog/>
                </ConfirmContextProvider>
            </Context.Provider>
            {modalActive ? <div className="modal-backdrop fade show"/> : ''}
        </div>
    );
}

export default App;
