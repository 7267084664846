import _get from 'lodash/get';
import isArray from 'lodash/isArray';
import _findIndex from 'lodash/findIndex';

export function normalizeErrorFields(errors) {
    let fields = {};
    Object.keys(_get(errors, 'form.errors.children', [])).forEach((key) => {
        if (_get(errors, `form.errors.children.${key}.children`)) {
            const nestErrors = _get(errors, `form.errors.children.${key}.children`);
            if (isArray(nestErrors)) {
                nestErrors.forEach((err, errIndex) => {
                    if (_get(err, `children`)) {
                        Object.keys(_get(err, `children`, []))
                            .forEach(childKey => {
                                if (_get(err, `children.${childKey}.errors.0`)) {
                                    fields[key] = {
                                        [errIndex]: {
                                            [childKey]: _get(err, `children.${childKey}.errors.0`)
                                        }
                                    };
                                }
                            })
                    } else if (_get(err, `errors.0`)) {
                        fields[key] = {[errIndex]: _get(err, `errors.0`)};
                    }
                })
            } else {
                Object.keys(nestErrors)
                    .forEach(childKey => {
                        if (_get(nestErrors, `${childKey}.errors.0`)) {
                            fields[key] = {
                                [childKey]: _get(nestErrors, `${childKey}.errors.0`)
                            };
                        }
                    })
            }


        } else if (_get(errors, `form.errors.children.${key}.errors.0`)) {
            const fieldError = _get(errors, `form.errors.children.${key}.errors.0`);
            if (fieldError) {
                fields[key] = fieldError;
            }
        }

    });

    return fields;

}

export function urlEncoder(data) {
    return Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
}

export function addOrUpdateModel(items, current) {
    let indexOld = _findIndex(items, {id: current.id});
    if (indexOld >= 0) {
        items[indexOld] = current;
    } else {
        items.unshift(current);
    }

    return items;
}

export function deleteArrayRow(list, key, value) {
    let data = list
    if (list) {
        data = list.filter(item => item[key] !== value)
    }
    return data
}