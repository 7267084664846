import axios from 'axios';
import history from '../functions/history';

export const makeFormData = fields => {
    const form = new FormData();

    Object.keys(fields).forEach(key => {
        const value = fields[key];

        if (Object.is(value) && !Array.isArray(value) && !(value instanceof File)) {
            Object.keys(value).forEach(subkey =>
                form.append(`${key}[${subkey}]`, value[subkey]));
        } else if (Array.isArray(value) && !(value instanceof File)) {
            value.forEach(val => {
                form.append(`${key}[]`, val);
            });
        } else {
            form.append(key, value);
        }
    });

    return form;
};
const request = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://yellow.test' : 'https://api.yellowwayinc.com',
    headers: {
        'Accept': 'application/json',
    }
});
request.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem('cabinet_token');

    if (jwtToken && !window.location.href.match(/(registration|shops)/)) {
        config.headers['authorization'] = `Bearer ${jwtToken}`
    }

    return config
}, error => {
    // Do something with request error here
    Promise.reject(error)
});
// API response interceptor
request.interceptors.response.use((response) => {
    if (response.config) {
        // if (['post'].includes(response.config.method)) {
        //     notification.success({message: 'Success'});
        // } else if (['put', 'patch'].includes(response.config.method)) {
        //     notification.success({message: 'Success update'});
        // } else if (['delete'].includes(response.config.method)) {
        //     notification.success({message: 'Success delete'});
        // }
    }

    return response.data
}, (error) => {

    let notificationParam = {
        message: ''
    };

    // Remove token and redirect
    if (error?.response?.status === 401 && localStorage.getItem('cabinet_token')) {
        notificationParam.message = 'Authentication Fail';
        notificationParam.description = 'Please login again';
        localStorage.removeItem('cabinet_token');
        history.push('/login');
        window.location.reload();
    }

    if (error?.response?.status === 404) {
        notificationParam.message = 'Not Found'
    }

    if (error?.response?.status === 403) {
        notificationParam.message = 'Access denied'
    }

    if (error?.response?.status === 500) {
        notificationParam.message = 'Internal Server Error'
    }

    if (error?.response?.status === 508) {
        notificationParam.message = 'Time Out'
    }
    if (error?.response?.status === 422) {
        notificationParam.message = 'Form values are invalid'
    }

    // notification.error(notificationParam);

    return Promise.reject(error);
});

export default request;
