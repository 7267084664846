import {
    LOAD_PROFILE,
    UNSET_PROFILE,
    SHOWED_MODAL,
    LOAD_TRACKING,
    SET_CURRENT_ITEM,
    SET_SECTION,
    UPDATE_STEP_MODAL,
    TOGGLE_ADD_MODAL,
    TOGGLE_ADDRESS_MODAL,
    LOAD_ADDRESS,
    SET_CURRENT_ADDRESS,
    ADD_UPDATE_TRACKING, ADD_UPDATE_SHOP, REMOVE_TRACKING, REMOVE_SHOP, LOAD_SHOP, SET_RESET_CODE
} from "../functions/constants";
import {addOrUpdateModel, deleteArrayRow} from "../functions/request-helper";

export const initialState = {
    customer: undefined,
    modalActive: false,
    visibleAddressModal: false,
    addresses: {
        1: [], 2: []
    },
    trackings: [],
    shops: [],
    total_count: 0,
    resetCode: null
};

export const MainReducer = (state, action) => {
    switch (action.type) {
        case LOAD_PROFILE:
            return {
                ...state,
                customer: action.payload
            }
        case UNSET_PROFILE: {
            return {
                ...state,
                customer: undefined
            }
        }
        case SHOWED_MODAL:
            return {
                ...state,
                modalActive: action.payload
            }
        case LOAD_TRACKING:
            return {
                ...state,
                trackings: action.payload.items,
                totalCount: action.payload.total_count,
                page: action.payload.page
            }
        case ADD_UPDATE_TRACKING:
            return {
                ...state,
                trackings: [...addOrUpdateModel(state.trackings, action.payload)]
            }
        case REMOVE_TRACKING:
            return {
                ...state,
                trackings: [...deleteArrayRow(state.trackings, 'id', action.payload)],
                total_count: state.total_count - 1
            }
        case LOAD_SHOP:
            return {
                ...state,
                shops: action.payload.items,
                totalCount: action.payload.total_count,
                page: action.payload.page
            }
        case ADD_UPDATE_SHOP:
            return {
                ...state,
                shops: [...addOrUpdateModel(state.shops, action.payload)]
            }
        case REMOVE_SHOP:
            return {
                ...state,
                shops: [...deleteArrayRow(state.shops, 'id', action.payload)],
                total_count: state.total_count - 1
            }
        case SET_CURRENT_ITEM:
            return {
                ...state,
                currentItem: action.payload.item,
                update: action.payload?.update
            }

        case SET_SECTION:
            return {
                ...state,
                section: action.payload,
                currentStep: 1
            }
        case UPDATE_STEP_MODAL:
            return {
                ...state,
                currentStep: action.payload
            }
        case TOGGLE_ADD_MODAL:
            return {
                ...state,
                showAddModal: action.payload.visible
            }
        case TOGGLE_ADDRESS_MODAL:
            return {
                ...state,
                visibleAddressModal: action.payload
            }
        case LOAD_ADDRESS:
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    [action.payload.country]: action.payload.items
                }
            }
        case SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload
            }
        case SET_RESET_CODE:
            return {
                ...state,
                resetCode: action.payload
            }
        default:
            return state;
    }
}