import CustomModal from "../../../components/CustomModal";
import {useContext, useEffect, useMemo, useState} from "react";
import Context from "../../../functions/context";
import {Form, Field} from 'react-final-form';
import {createForm} from "final-form";
import {addOrUpdateModel, normalizeErrorFields} from "../../../functions/request-helper";
import {composeValidators, renderErrorBlock, requiredConstraint} from "../../../functions/validator";
import {createAddress, loadDistricts, loadRegions, updateAddress} from "../../../functions/api";
import SimpleSelect from "../../../components/SimpleSelect";
import {LOAD_ADDRESS, TOGGLE_ADDRESS_MODAL} from "../../../functions/constants";

const formatToForm = (values) => {
    return {
        ...values,
        firstName: values.first_name,
        lastName: values.last_name,
        region: values.region?.id,
        district: values.district?.id,
        firstAddress: values.first_address,
        zipCode: values.zip_code,
        country: values.country?.id,
    }
}

export default function AddressModal({country, secondary}) {
    const {
        mainState: {visibleAddressModal, currentAddress, addresses: items},
        mainDispatch,
    } = useContext(Context);
    const [submitting, setSubmitting] = useState(false);
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);

    const onSubmit = async (values) => {
        setSubmitting(true);
        try {
            let address;
            if (!values?.id) {
                values.country = country;
                address = await createAddress(values);
            } else {
                address = await updateAddress(values.id, values);
            }
            mainDispatch({
                type: LOAD_ADDRESS,
                payload: {country, items: addOrUpdateModel(items[country], address)}
            })
            mainDispatch({type: TOGGLE_ADDRESS_MODAL, payload: false})
        } catch (res) {
            if (res?.response?.status === 422) {
                const error = res.response.data;
                const fieldErrors = normalizeErrorFields(error, values);
                if (Object.keys(fieldErrors).length) {
                    return fieldErrors;
                }
            }
        } finally {
            setSubmitting(false);
        }
    }
    useEffect(() => {
        loadRegions({country}, 1, 1000)
            .then(({items}) => {
                setRegions(items)
            });
        return () => {
            form.reset({});
        }

    }, []);
    useEffect(() => {
        if (currentAddress?.region?.id) {
            handleRegionsChange(currentAddress.region.id);
        }
    }, [currentAddress?.id])
    const handleRegionsChange = (value) => {
        if (value) {
            loadDistricts({region: value}, 1, 1000)
                .then(({items}) => {
                    setDistricts(items);
                })
        }
    };
    const form = useMemo(() => {
        return createForm({
            onSubmit,
            initialValues: currentAddress?.id ? formatToForm(currentAddress) : {}
        })
    }, [visibleAddressModal, currentAddress?.id])
    return (
        <CustomModal
            visible={visibleAddressModal}
            onCancel={() => mainDispatch({type: TOGGLE_ADDRESS_MODAL, payload: false})}
            title={country === 2 ? 'Добавить получателя' : 'Добавить отправителя'}
            size="lg"
            secondary={secondary || false}
        >
            <div className="modal-body" id="parcelsModal">
                <Form
                    form={form}
                    initialValues={currentAddress?.id ? formatToForm(currentAddress) : {}}
                    subscription={{values: true, initialValues: true}}
                    onSubmit={onSubmit}
                    render={({handleSubmit}) => {
                        return (
                            <form onSubmit={handleSubmit} className="row g-2 parcels-form">
                                <div className="col-12">
                                    <h1>Данные</h1>
                                </div>
                                <Field name="firstName" validate={composeValidators(requiredConstraint)}>
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Имя</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="lastName">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Фамилия</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="passport">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Серия и номер паспорта</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                    pattern={country === 2 ? "^[a-zA-Z]{0,2}[0-9]{0,7}$" : ""}
                                                    onChange={(e) => {
                                                        if (e.target.validity.valid && country === 2) {
                                                            input.onChange(e.target.value.toLocaleUpperCase());
                                                        } else if (country === 1) {
                                                            input.onChange(e.target.value);
                                                        }

                                                    }}
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="phones.0" validate={composeValidators(requiredConstraint)}
                                       defaultValue={country === 2 ? '+998' : '+1'}>
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Номер телефона</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                    pattern="^[+]{0,1}?[0-9]{0,12}$"
                                                    onChange={(e) => {
                                                        if (e.target.validity.valid) {
                                                            input.onChange(e.target.value);
                                                        }
                                                    }}
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="phones.1">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Дополнительный номер</label>
                                                <input
                                                    {...input}
                                                    pattern="^[+]{0,1}?[0-9]{0,12}$"
                                                    onChange={(e) => {
                                                        if (e.target.validity.valid) {
                                                            input.onChange(e.target.value);
                                                        }
                                                    }}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="zipCode">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">ZIP-код</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>

                                <div className="col-md-12">
                                    <h1>Адрес</h1>
                                </div>
                                <Field name="region">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Регион</label>
                                                <SimpleSelect
                                                    {...input}
                                                    onChange={(value) => {
                                                        handleRegionsChange(value);
                                                        input.onChange(value);
                                                    }}
                                                    placeholder="Выберите регион"
                                                    options={regions.map((r) => {
                                                        return {
                                                            value: r.id,
                                                            label: r.name
                                                        }
                                                    })}/>
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="district">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Район</label>
                                                <SimpleSelect
                                                    {...input}
                                                    placeholder="Выберите район"
                                                    options={districts.map((r) => {
                                                        return {
                                                            value: r.id,
                                                            label: r.name
                                                        }
                                                    })}/>
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="firstAddress" validate={composeValidators(requiredConstraint)}>
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Улица</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="house">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Дом</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>
                                <Field name="apartment">
                                    {({input, meta}) => {
                                        return (
                                            <div className="col-md-4">
                                                <label className="form-label">Квартира</label>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                />
                                                {renderErrorBlock(meta)}
                                            </div>
                                        )
                                    }}
                                </Field>

                                <div className="col-12 text-end mt-3 mb-3">
                                    <button type="button"
                                            onClick={() => mainDispatch({type: TOGGLE_ADDRESS_MODAL, payload: false})}
                                            className="btn reciever-cancel-btn">
                                        Отменить
                                    </button>
                                    &nbsp;
                                    <button disabled={submitting} type="submit" className="btn reciever-add-btn">
                                        {currentAddress ? 'Обновить' : 'Добавить'}
                                    </button>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>
        </CustomModal>
    )
}