import {useMemo} from "react";

export default function ShopDetail({values}) {
    const totalPrice = useMemo(() => {
        console.log(values)
        return (values?.records || []).reduce((t, r) => {
            return r.quantity * r.price + t;
        }, 0)

    }, [])

    return (
        <>
            <div className="parcels-list-declaration">
                <h6 className="parcels-title">Декларация</h6>
                {(values?.records || []).map((record, index) => {
                    return (
                        <div key={`product_${index}`} className="item-declaration">
                            <div>
                                <div className="item-deckaration__name">Наименование</div>
                                <div className="item-deckaration__value">{record.name}</div>
                            </div>
                            <div>
                                <div className="item-deckaration__name">Количество</div>
                                <div className="item-deckaration__value">{record.quantity}</div>
                            </div>
                            <div>
                                <div className="item-deckaration__name">Стоимость</div>
                                <div className="item-deckaration__value">${record.quantity * record.price}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="parcels-list-details">
                <h6 className="parcels-title">Товар(ы) для покупки</h6>
                <ul>
                    <li className="item-detail">
                        <span className="item-left">Общая стоимость товаров</span>
                        <span className="item-right">${totalPrice}</span>
                    </li>
                    <li className="item-detail">
                        <span className="item-left">Комиссия за выкуп (10%)</span>
                        <span className="item-right">${totalPrice * 0.1}</span>
                    </li>
                    <li className="item-detail">
                        <span className="item-left">Итоговая сумма</span>
                        <span className="item-right">${totalPrice + totalPrice * 0.1}</span>
                    </li>
                </ul>
                <blockquote className="blockquote">
                    <p className="mb-0">Комментарий для оператора</p>
                    <p className="text-muted">
                        {values?.note || ''}
                    </p>
                </blockquote>
            </div>
        </>
    )
}