import {loadAddress, loadDistricts} from "../functions/api";
import {LOAD_ADDRESS} from "../functions/constants";

export function loadAddressAction(country, mainDispatch) {
    return loadAddress({country})
        .then(({items: data}) => {
            let districtIds = data.reduce((r, d) => {
                if (d.district) {
                    r.push(d.district);
                }
                return r;
            }, []);
            if (districtIds.length) {
                loadDistricts({id: districtIds}, 1, 100)
                    .then(({items: districts}) => {
                        data.map(d => {
                            if (d.district) {
                                const dist = districts.find(ds => ds.id === d.district);
                                if (dist) {
                                    d.district = dist;
                                }
                            }
                            return d;
                        });
                        mainDispatch({
                            type: LOAD_ADDRESS,
                            payload: {country, items: data}
                        })
                    })
            } else {
                mainDispatch({
                    type: LOAD_ADDRESS,
                    payload: {country, items: data}
                })
            }
        })
}
