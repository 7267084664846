import CustomModal from "./CustomModal";

export default function IntroInfoModal({visible, onCancel}) {
    return <CustomModal
        visible={visible}
        onCancel={onCancel}
    >
        <div className="modal-body">
            Уважаемые клиенты, в данный момент веб-сайт находится на финальной стадии разработки и в ближайшее время
            приступит к работе.
            Спасибо что выбрали нас.
        </div>
    </CustomModal>
}