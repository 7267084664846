import useConfirm from "../functions/use-confirm";

const ConfirmDialog = () => {
    const {onConfirm, onCancel, confirmState} = useConfirm();

    return confirmState.show ? (
        <div className="modal fade show" style={{display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header   border-0">
                        <h5 className="modal-title mx-auto" id="deleteModalLabel">удалить?</h5>
                    </div>
                    <div className="modal-body text-center">
                        <div className="col-12">
                            <p className="logout-text text-muted">{confirmState?.text && confirmState.text}</p></div>
                        <div className="col-12 mt-3">
                            <button className="btn btn-danger logout-btn m-1" onClick={onConfirm}>удалить ?</button>
                            <button type="button" onClick={onCancel}
                                    className="btn cancel-btn  m-1">Отменить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : ''
};
export default ConfirmDialog;