import {useMemo} from "react";

export default function TrackingDetail({values}) {
    const totalPrice = useMemo(() => {
        return (values?.records || []).reduce((t, r) => {
            return r.quantity * (r.unitPrice || r.unit_price) + t;
        }, 0)

    }, [])

    return (
        <>
            <div className="parcels-list-declaration">
                <h6 className="parcels-title">Декларация</h6>
                {(values?.records || []).map((record, index) => {
                    return (
                        <div key={`product_${index}`} className="item-declaration">
                            <div>
                                <div className="item-deckaration__name">Трек-номер</div>
                                <div className="item-deckaration__value">{record.tracking}</div>
                            </div>
                            <div>
                                <div className="item-deckaration__name">Количество</div>
                                <div className="item-deckaration__value">{record.quantity}</div>
                            </div>
                            <div>
                                <div className="item-deckaration__name">Стоимость</div>
                                <div className="item-deckaration__value">${record.quantity * (record.unitPrice || record.unit_price)}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="parcels-list-details">
                <h6 className="parcels-title">Общая</h6>
                <ul>
                    <li className="item-detail">
                        <span className="item-left">Общая стоимость товаров</span>
                        <span className="item-right">${totalPrice}</span>
                    </li>
                </ul>
            </div>
        </>
    )
}