import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            ru: {
                translation: {
                    shop: {
                        popular: 'Популярные магазины',
                        marketplace: 'Маркетплейсы',
                        store: 'Универмаги',
                        clothes: 'Одежда',
                        shoes: 'Обувь',
                        beauty: 'Товары для красоты',
                        children: 'Детские товары',
                        electronics: 'Электроника',
                        appliances: 'Бытовая техника',
                        sport: 'Спортивные товары',
                        health: 'Товары для здоровья',
                        other: 'Другое'
                    },
                    'uzbek-swiper-1-title': 'Быстрая и надёжная доставка из США',

                }
            },
            uz: {
                translation: {}
            },
            en: {
                translation: {}
            }
        },
        fallbackLng: localStorage.getItem('locale') || 'ru',
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default i18n;