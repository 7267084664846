import {useContext, useEffect} from "react";
import Context from "../../../functions/context";
import {SET_CURRENT_ADDRESS, TOGGLE_ADDRESS_MODAL} from "../../../functions/constants";
import {loadAddressAction} from "../../../reducer/action";

export default function AddressChooser() {
    const {mainState: {currentAddress, addresses}, mainDispatch} = useContext(Context);

    useEffect(() => {
        loadAddressAction(2, mainDispatch);
    }, []);

    return (
        <div className="col-12">
            {(addresses[2] || []).map((address) => (
                <b
                    onClick={() => {
                        console.log(address);
                        mainDispatch({type: SET_CURRENT_ADDRESS, payload: address});
                    }}
                    className={`parcels-address ${address.id === currentAddress?.id ? 'active' : ''}`}
                    key={`address_${address.id}`}
                >
                    <p><span><i className="ri-user-6-line"></i></span>{address.full_name}</p>
                    <p><span><i className="ri-map-pin-line"></i></span>{address.label}</p>
                    <p><span><i className="ri-phone-line"></i></span>{(address.phones || []).join(' ')}</p>
                </b>
            ))}

            <div className="col flex-center mt-3">
                <button className="btn new-address-btn"
                        onClick={() => mainDispatch({type: TOGGLE_ADDRESS_MODAL, payload: true})}
                >
                    <i className="ri-add-line"></i> Добавить новый адрес
                </button>
            </div>

        </div>
    )
}