import React from 'react';
import Select from 'react-select'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'

const SimpleSelect = ({placeholder, options, onChange, value}) => {
    const handleSelect = (option) => {
        if (onChange) {
            onChange(option?.value);
        }
    };
    return (
        <Select
            isClearable={true}
            options={options}
            placeholder={placeholder}
            style={{width: '100%'}}
            onChange={handleSelect}
            value={!isObject(value) && isEmpty(value) ? options.find(opt => opt.value === value) : value}
        >
        </Select>
    );
};

export default SimpleSelect;