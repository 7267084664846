import React, {useContext, useEffect} from "react";
import Context from "../functions/context";
import {SHOWED_MODAL} from "../functions/constants";

function CustomModal({visible, title, onCancel, children, size, cssClass, secondary, id}) {
    const {mainDispatch} = useContext(Context);

    useEffect(() => {
        if (visible) {
            mainDispatch({type: SHOWED_MODAL, payload: true});
        }
        return () => {
            if (visible && !secondary) {
                mainDispatch({type: SHOWED_MODAL, payload: false});
            }
        }

    }, [visible]);
    return (
        <div className={`modal fade ${visible ? 'show' : ''} ${cssClass || ''}`}
             style={{display: visible ? 'block' : 'none'}} id={id || 'mainModal'}>
            <div className={`modal-dialog modal-dialog-centered ${size ? `modal-${size}` : ''}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            {title}
                        </h4>
                        <button type="button" className="btn-close" onClick={onCancel}/>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CustomModal;