import {useContext} from 'react';
import ConfirmContext from "./confirm-context";
import {HIDE_CONFIRM, SHOW_CONFIRM} from "../reducer/confirm";
import {SHOWED_MODAL} from "./constants";
import Context from "./context";

let resolveCallback;

function useConfirm() {
    const [confirmState, confirmDispatch] = useContext(ConfirmContext);
    const {mainDispatch} = useContext(Context);
    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };
    const confirm = text => {
        confirmDispatch({
            type: SHOW_CONFIRM,
            payload: {
                text
            }
        });
        mainDispatch({type: SHOWED_MODAL, payload: true});
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const closeConfirm = () => {
        confirmDispatch({
            type: HIDE_CONFIRM
        });
        mainDispatch({type: SHOWED_MODAL, payload: false});
    };

    return {confirm, onConfirm, onCancel, confirmState};
}

export default useConfirm;