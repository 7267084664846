import request from './request';
import {urlEncoder} from "./request-helper";

export const fetchShopList = () =>
    request.get('/website/shops')
        .then(({items}) => items);

export const postRegistration = (values) =>
    request.post('/cabinet/customers/register', values)
        .then((customer) => customer);

export const updateProfile = (values) =>
    request.put('/cabinet/customers', values)
        .then((customer) => customer);

export const changePassword = (values) =>
    request.post('/cabinet/customers/change-password', values);

export const verifyRegistration = (customerId, code) =>
    request.put(`/cabinet/customers/register/${customerId}/verification`, {code})
        .then((customer) => customer);


export function loginUser(username, password) {
    return request.post('/cabinet/security/login_check', urlEncoder({
        _username: username,
        _password: password
    }), {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then((data) => {
        localStorage.setItem('cabinet_token', data.token);
        return loadProfile(data.token).then(user => {
            return {token: data.token, user};
        })
    });
}

export function sendVerificationCode(email) {
    return request
        .post('/cabinet/forgot-password', {email})
}

export function checkCode(code) {
    return request
        .post('/cabinet/forgot-password/check', {code})
}

export function resetPassword(code, password) {
    return request
        .put('/cabinet/forgot-password/reset', {code, password})
}

export function loadProfile(token = undefined) {
    if (!token) {
        token = localStorage.getItem('cabinet_token');
    }
    return request.get('/cabinet/customers/me', {
        headers: {
            'authorization': `Bearer ${token}`
        }
    })
        .then((user) => user);
}

export const shopRate = () =>
    request.get('/cabinet/options/shop-rate')
        .then(data => data);

export const loadRegions = (params, page, size) =>
    request.get('/regions', {params: {...params, page, size}})

export const loadDistricts = (params, page, size) =>
    request.get('/districts', {params: {...params, page, size}});

export const loadAddress = (params, page, size) =>
    request.get('/cabinet/address', {params: {...params, page, size}});

export const createAddress = (values) =>
    request.post('/cabinet/address', values)
        .then((address) => address)

export const updateAddress = (id, values) =>
    request.put(`/cabinet/address/${id}`, values)
        .then((address) => address);

export const deleteAddress = (id) =>
    request.delete(`/cabinet/address/${id}`)

export const loadTracking = (filter, page) =>
    request.get('/cabinet/trackings', {params: {...filter, page}})
        .then(res => res);

export const loadTrackingSummery = () =>
    request.get('/cabinet/trackings/summery')
        .then(res => res);

export const createTracking = (values) =>
    request.post('/cabinet/trackings', values)
        .then(item => item)

export const updateTracking = (id, values) =>
    request.put(`/cabinet/trackings/${id}`, values)
        .then(item => item);

export const deleteTracking = (id) =>
    request.delete(`/cabinet/trackings/${id}`)

export const createShop = (values) =>
    request.post('/cabinet/shops', values)
        .then(shop => shop);

export const updateShop = (id, values) =>
    request.put(`/cabinet/shops/${id}`, values)
        .then(shop => shop);

export const loadShops = () =>
    request.get('/cabinet/shops')
        .then(res => res);


export const deleteShop = (id) =>
    request.delete(`/cabinet/shops/${id}`);

export const postFeedbackWebsite = (values) => request.post('/website/feedback', values);
